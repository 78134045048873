@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply w-full h-full;
}

body {
  font-family: 'maison neue', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-grey-900 overscroll-x-none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media screen and (max-width: 700px) {
  .intercom-lightweight-app-launcher {
    bottom: 85px !important;
  }
}

@media screen and (max-width: 700px) {
  .intercom-dfosxs {
    bottom: 85px !important;
  }
}