html {
  --primary-foreground: #1C1F1F;
  /* grey-800 */
}


.sd-navigation__prev-btn {
  @apply hidden;
}

.sd-row {
  min-width: 300px;
}

.sd-row.sd-clearfix {
  /* We need to be more specific than .sd-row to remove the margin applied by default by SurveyJS */
  @apply mt-8;
}

.surveyRoot form {
  @apply w-full h-full;
}

.sv-action__content,
.sv-action {
  @apply w-full;
}

[data-key="num-sp"] {
  @apply hidden;
}

.sd-row.sd-clearfix>[data-key] {
  @apply h-full;
}

.textareaSurveyJs {
  /* Survey JS enables resizing with javascript. We need to disable it here using important to override the resizing behavior */
  resize: none !important;
}

.sd-question__description--under-input:before {
  content: '';
  @apply h-16 w-16;
  @apply mr-12;
  @apply block;
  @apply bg-contain bg-no-repeat bg-center;
  background-image: url("../../../../../shared/view/icons/LightBulbIcon.svg");
}

.sd-question__description--under-input>span {
  @apply flex-[fit-content];
}

.sd-question__description--under-input:has(> span:empty) {
  /* Even if there is no description, SurveyJS still generates a question description div, so we need to hide it */
  display: none;
}

/* SurveyJS HTML questions */
.sd-question--html {
  @apply h-full;
}

.sd-html div {
  @apply text-grey-0
}

.sd-html {
  @apply flex flex-col flex-1 text-grey-0
}

.sd-html h1 {
  @apply font-sans-ext whitespace-normal text-grey-0 text-title-l font-semibold pb-24 leading-tight;
}

.sd-html img {
  @apply mx-auto pb-24;
}

.sd-html h2 {
  @apply font-sans-ext whitespace-normal text-grey-0 text-title-s font-semibold pb-16 leading-tight;
}

.sd-text {
  width: 100%;
}

.sd-html ul {
  list-style: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.sd-html li {
  @apply mb-8;
}

.sd-html .tooltip {
  @apply bg-grey-800 p-12 rounded-lg gap-16 flex items-center mb-16;
}

/* End of SurveyJS HTML questions */

.checkboxLabel:after {
  content: '';
  @apply w-24 h-12;
  @apply block;
  @apply bg-contain bg-no-repeat bg-center;
  background-image: url("../../../../../shared/view/icons/RightChevronIcon.svg");
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.sv-components-row {
  width: 100%;
  height: 100%;
  min-width: 300px;
}

.sv-components-column {
  align-items: center;
}

.sv-components-row>.sv-components-column--expandable {
  width: 100%;
}

.sd-html, .sd-html td, .sd-html span, .sd-html div, .sd-html p {
  font-family: 'maison neue', -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 1.5;
}

.neon-effect {
  text-shadow: 0px 0px 14px #3EC7AE;
}
