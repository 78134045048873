.sub-step-stepper {
  background-color: black;
  height: 4px;
  width: 0;
  transition: width ease-in-out 0.5s;
}

.gradient-font-color {
  background: linear-gradient(180deg, #559DDC 0%, #C9E6FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.leading-normal-important {
  line-height: 1.5 !important;
}

.checkboxContainer input:checked~.checkboxInput {
  background-color: #ffffff;
}

.bullet-line::before {
  position: absolute;
  left: 18px;
  top: 42px;
  height: 22px;
  content: " ";
  width: 4px;
}